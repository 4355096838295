<template>
  <div>
    <ActivityAddForm :parcel-id="id" @success="onSuccess" />
  </div>
</template>

<script>
export default {
  components: {
    ActivityAddForm: () => import('./ActivityAddForm.vue')
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    onSuccess(action) {
      this.$emit('success', action);
    }
  }
};
</script>

<style>
</style>
